import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/layout'
  },
  {
    path: '/layout',
    component: () => import('@/views/layout'),
    name: 'layout',
    meta: { keepAlive: true }
  },
  {
    path: '/layout/detail',
    component: () => import('@/views/layout/detail'),
    name: 'detail'
  },
  {
    path: '/Contact',
    component: () => import('@/components/Contact.vue'),
    name: 'Contact'
  }
]

const router = new VueRouter({
  routes
})

export default router
